html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.kiwi-wrap {
  font-size: 90%;
  line-height: 1.6em;
  font-family: Source Sans Pro, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow: hidden;
}
/* .kiwi-workspace has ::before and ::after 4px above itself for the connection status */
.kiwi-workspace {
  position: relative;
  margin-left: 220px;
  left: 0;
  top: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: left 0.2s, margin-left 0.2s;
}
.kiwi-workspace::before,
.kiwi-workspace::after {
  position: absolute;
  content: '';
  left: 0;
  right: auto;
  margin-top: -4px;
  width: 100%;
  height: 7px;
  z-index: 0;
  transition: width 0.3s;
}
.kiwi-workspace::after {
  right: 0;
  left: auto;
  width: 0;
}
.kiwi-workspace--disconnected::before {
  width: 0;
}
.kiwi-workspace--disconnected::after {
  width: 100%;
}
.kiwi-workspace-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.kiwi-statebrowser {
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  bottom: 0;
  z-index: 11;
  /* Must be at least 1 higher than the workspace :after z-index; */
  transition: left 0.145s, margin-left 0.145s;
}
.kiwi-container {
  flex-grow: 1;
  /* The nicklist scroller needs some type of height set on it's parent, but since we use flexbox
       it starts conflicting on firefox. Luckily flexbox ignores this 5% and resizes it as we intend
       anyway. */
  height: 5%;
}
.kiwi-main-mediaviewer {
  max-height: 70%;
  overflow: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.kiwi-controlinput {
  z-index: 2;
}
/* Small screen will cause the statebrowser to act as a drawer */
@media screen and (max-width: 769px) {
  .kiwi-workspace {
    left: 0;
    margin-left: 0;
  }
  .kiwi-statebrowser {
    left: -220px;
  }
  .kiwi-wrap--statebrowser-drawopen .kiwi-statebrowser {
    left: 0;
  }
  .kiwi-wrap--statebrowser-drawopen .kiwi-workspace {
    left: 75%;
    transition: left 0.1s;
    transition-delay: 0s;
  }
}
